@import '../../styles/base';

.default-password {
  input[type='text'] {
    border: 0;
    box-shadow: none;
    font-size: 18px;
    padding: 15px 0;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
    background: none;
  }
}
