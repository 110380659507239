@import '../../../styles/base';

.form-container {
  overflow: hidden;
  padding: 20px;
}

.form {
  width: 500px;
  @extend %card;
}

.item {
  margin-bottom: 15px;

  label {
    font-size: 15px;
  }

  .required {
    color: $error-color;
    display: inline-block;
    margin-left: 5px;
  }

  input[type='radio'] {
    width: auto;
  }

  input:disabled,
  input[disabled] {
    background: #eee;
  }

  textarea:disabled,
  textarea[disabled] {
    background: #eee;
  }
}

.button-item {
  text-align: right;

  button {
    margin-left: 10px;

    &:disabled,
    &[disabled] {
      opacity: 0.7;
      cursor: default;
    }
  }
}

.form-error {
  color: $error-color;
  display: inline-block;
  margin-top: 5px;
  font-size: 16px;
  padding-right: 8px;
}

.link {
  color: $theme-color;
  text-decoration: none;
  display: block;
  padding: 10px 0;
  cursor: pointer;
}

.inlineLink {
  composes: link;
  display: inline-block;
  margin-left: 5px;
}

.order-info {
  border-left: 3px solid $theme-color;
  margin-bottom: 20px;

  p {
    margin: 0 0 5px 10px;
  }
}
