label {
  color: #666;
  margin-bottom: 8px;
  display: block;
}

select,
input[type='text'],
input[type='email'],
input[type='password'],
input[type='tel'],
input[type='number'],
textarea {
  height: 38px;
  line-height: 38px;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: $font-size-normal;
  border: 1px solid #ccc;
  width: 100%;
  color: $text-color;
  transition: border-color 0.2s ease-in-out;

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    background: #eee;
    opacity: 0.6;
    pointer-events: none;
  }
}

input[type='text'],
input[type='email'],
input[type='password'],
input[type='tel'],
input[type='number'] {
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid #ccc;

  &:focus {
    border-color: $theme-color;
  }

  &::-webkit-input-placeholder {
    color: $placeholder-color;
  }

  ::-moz-placeholder {
    color: $placeholder-color;
  }

  :-ms-input-placeholder {
    color: $placeholder-color;
  }

  :-moz-placeholder {
    color: $placeholder-color;
  }
}

textarea {
  &:focus {
    border-color: $theme-color;
  }

  &::-webkit-input-placeholder {
    color: $placeholder-color;
  }

  ::-moz-placeholder {
    color: $placeholder-color;
  }

  :-ms-input-placeholder {
    color: $placeholder-color;
  }

  :-moz-placeholder {
    color: $placeholder-color;
  }
}

select,
.rw-dropdown-list {
  cursor: pointer;
}

textarea {
  height: 150px;
}

fieldset {
  display: block;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  .error {
    color: $error-color;
    font-size: 14px;
    padding-left: 8px;
    padding-top: 2px;
  }
}

.form-title {
  // padding: 10px 0;
  padding: 3px 0 10px;
  border-bottom: 1px dashed #ccc;
  margin-bottom: 24px;

  .title {
    margin-top: 0;
    margin-bottom: 8px;
    line-height: 1;
    @extend %text-ellipsis;
  }

  .sub {
    margin: 0;
    font-size: 14px;
    color: #666;
  }
}

.form-section {
  margin-bottom: 20px;

  h3 {
    font: 600 15px/2 $font-family;
    border-bottom: 1px dashed #ccc;
    margin-bottom: 15px;
  }
}

.radio-group {
  label {
    display: inline-block;
    vertical-align: top;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.select-field {
  position: relative;

  .create-new {
    font-size: 14px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
}
