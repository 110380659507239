@import './colors';

%display-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

%btn {
  display: inline-block;
  background: $theme-color;
  color: $main-color;
  text-decoration: none;
  padding: 0 15px;
  border-radius: 5px;
  font: #{$font-size-normal}/ 35px $font-family;
  cursor: pointer;
  border: 0;
  box-shadow: 0 5px 4px -5px rgba(#333, 0.75);

  &:not(:last-child) {
    margin-right: 10px;
  }

  &.warning {
    background: $warning-color;
  }

  &.error,
  &.red {
    background: $error-color;
  }

  &.primary,
  &.green {
    background: $successful-color;
  }
}

%btn-group {
  text-align: right;
  padding: 15px 0;
}

%card {
  width: 500px;
  background: #fff;
  padding: 20px 30px;
  margin: 30px auto;
  border-radius: 8px;
  box-shadow: 0 3px 8px 0 rgba(#333, 0.3);
  // max-height: calc(100vh - 150px);
  overflow: auto;
}

%link {
  color: $theme-color;
  text-decoration: none;
  display: block;
  padding: 10px 0;
  cursor: pointer;

  i {
    margin-right: 5px;
  }
}
