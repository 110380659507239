@import '../../styles/vars';

.root {
  position: relative;

  section {
    overflow: hidden;
  }

  :global(.corner-btn-group) {
    right: 0;
    top: 0;
  }
}

.table {
  text-align: left;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid $table-border-color;

  tr {
    border-bottom: 1px solid $table-border-color;
  }

  th {
    background: $table-header-bg-color;
  }

  th,
  td {
    padding: 10px;
  }
}

.title {
  font-size: 17px;
}

.link {
  color: $theme-color;
  text-decoration: none;
  display: block;
  padding: 10px 0;
  cursor: pointer;

  i {
    margin-right: 5px;
  }
}

.linkWrapper {
  display: flex;
  justify-content: space-between;
}

.empty {
  background: $card-bg-color;
  border: 1px solid $card-border-color;
  padding: 50px;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
}
