@import '../../styles/base';

.form {
  @extend %card;
}

.item {
  margin-bottom: 10px;

  label {
    font-size: 15px;
  }

  .required {
    color: $error-color;
    display: inline-block;
    margin-left: 5px;
  }
}

.error {
  color: $error-color;
  display: inline-block;
  margin-top: 5px;
}

.link {
  color: $theme-color;
  text-decoration: none;
  display: block;
  cursor: pointer;
}
