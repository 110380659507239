@import '../../../styles/base';

.root {
  padding: 20px;
  position: relative;
  flex-basis: 100%;

  h2 {
    margin-bottom: 40px;
    padding-right: 100px;
  }
}

.time {
  line-height: 1.6;
  border-radius: 5px;
  margin-bottom: 5px;
  letter-spacing: 0.3px;
  padding: 0 1px;
  font-size: 14px;
  position: absolute;
  right: 0;
  top: 0;
  font-family: $font-family-mono;
  font-weight: bold;
}

:global(.card-box) {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  .card-title {
    margin-bottom: 10px;
    font: bold 16px/1.2 $font-family-condensed;
    color: #555;
    padding: 0 1px;
  }
}

.card {
  background: $main-color;
  box-shadow: 0 1px 3px 0 rgba(182, 182, 182, 0.5);
  // font-size: $font-size-small;
  font-weight: $font-weight-light;
  padding: 3px 10px;
  border-radius: 5px;
}

.table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  th {
    font: 400 15px/1 $font-family-condensed;
    white-space: nowrap;
    padding: 8px;
    box-shadow: 0 1px 0 0 rgba(182, 182, 182, 0.5);

    &:last-child {
      .cell {
        border-right: none;
      }
    }

    > .cell {
      display: block;
      font-weight: bold;
    }
  }

  td {
    font: 400 15px/1.5 $font-family-condensed;
    padding: 5px 10px;

    > .cell {
    }
  }
}

.diff {
  .cell {
    padding: 5px;
    margin: 0;
    line-height: 20px;
  }

  .before {
    background-color: #ffeef0;
    text-decoration: line-through;

    .location {
      text-decoration: line-through;
    }
  }

  .after {
    background-color: #e6ffed;
  }
}

.row {
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  .diff .cell {
    padding: 0;
  }

  .note {
    padding: 10px;
  }

  :global(.btn-group) {
    padding: 10px 0;
  }
}

.location {
  padding: 0 10px;
  border: 1px solid $theme-color;
  color: $theme-color;
  display: inline-block;
  margin: 10px 0 10px 5px;
  background: $main-color;
  border-radius: 5px;
  font: 400 14px/25px $font-family-condensed;

  &:first-child {
    margin-left: 10px;
  }
}

.link {
  @extend %link;
}

.empty {
  background: $card-bg-color;
  border: 1px solid $card-border-color;
  padding: 150px 0;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  border-radius: 5px;
}

.trash {
  font-size: 18px;
  color: $theme-color;
  cursor: pointer;
  padding-right: 10px;
}
