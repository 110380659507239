@import '../../styles/base';

$_menu-height: 57px;

.content {
  padding: 20px;
  flex-basis: 100%;
}

.link {
  color: $theme-color;
  text-decoration: none;
  display: block;
  padding: 10px 0;
  cursor: pointer;
}
