:global {
  .ReactTable.custom {
    background: $main-color;
    color: $text-color;
    font-size: $font-size-normal;
    border-radius: 4px;

    .rt-tr {
      &.-even {
        background: rgba(#ccc, 0.05);
      }
    }

    .rt-tr-group {
      &:hover {
        background: rgba(#e1f5fe, 0.2);
      }
    }

    .rt-tbody {
      .rt-th,
      .rt-td {
        border: 0;
        padding: 8px 15px;
        font-family: $font-family-condensed;
      }

      .rt-td {
        display: flex;
        border-right: none;
        font-weight: $font-weight-normal;
        justify-content: flex-start;
        align-items: center;
        white-space: normal;

        &.align-left {
          justify-content: flex-start;
        }

        &.manage {
          padding: 8px 10px 8px 0;

          .fas,
          .fa {
            margin: 0 auto;
            cursor: pointer;
            position: relative;
            text-decoration: none;
            font-size: 13px;
            color: $tgk-blue;
            width: 30px;
            height: 30px;
            display: block;
            border-radius: 50%;
            background: #fff;
            box-shadow: 0 2px 5px rgba(#ccc, 0.2);
            transition: box-shadow 0.2s ease, color 0.2s ease, background 0.2s ease;
            will-change: box-shadow, color, background;

            &.fa-pen {
              font-size: 11px;
            }

            &.fa-times {
              &:hover {
                background: $warning-color;
                color: #fff;
              }
            }

            &::before {
              @extend %center;
            }

            &:hover {
              box-shadow: 0 2px 5px #888;
            }
          }
        }
      }
    }

    .rt-thead {
      &.-header {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
        margin-bottom: 3px;
      }

      .rt-th {
        border: 0;
        display: flex;
        font-weight: bold;
        text-align: left;
        border-color: #d8d8d8;
        outline: none;
        position: relative;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 15px;

        &.align-left {
          justify-content: flex-start;
        }

        &.-sort-desc,
        &.-sort-asc {
          color: $theme-color;
          box-shadow: none;

          &::after {
            content: '';
            display: block;
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
          }
        }

        &.-sort-desc {
          &::after {
            border-bottom: 6px solid $theme-color;
          }
        }

        &.-sort-asc {
          &::after {
            border-top: 6px solid $theme-color;
          }
        }
      }
    }

    .table-icon > a {
      color: $theme-color;
      display: block;
      line-height: 30px;
      font-size: $font-size-normal;
    }

    // .details {
    //   display: inline-block;
    //   background: $theme-color;
    //   color: $main-color;
    //   padding: 5px;
    //   text-decoration: none;
    // }

    .-pagination {
      margin: 20px auto;
      display: flex;
      justify-content: center;
      align-items: center;

      > * {
        &:not(:last-child) {
          margin-right: 15px;
        }
      }

      .-btn {
      }

      .-previous {
      }

      .-next {
      }

      .-center {
        display: flex;
        justify-content: center;
        align-items: center;

        .-pageInfo {
          display: flex;
          justify-content: center;
          align-items: center;

          .-pageJump {
            margin-left: 15px;

            input {
              width: 60px;
            }
          }

          .-totalPages {
            margin-right: 15px;
          }
        }
      }
    }
  }
}

table {
  table-layout: fixed;
}
