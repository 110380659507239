@import '../../styles/base';

.root {
  height: 100%;
  width: $menu-width;
  position: fixed;
  background: $menu-bg-color;
  color: $text-color;
  padding: #{$header-height + 24px} 20px 0 0;
  box-shadow: inset 0 0 10px #ccc;
}

.list-title {
  text-transform: uppercase;
  font: bold 17px/2 $font-family;
  color: #999;
  padding-left: 20px;
  margin-bottom: 12px;
  border-bottom: 1px dashed #ccc;
}

.menu {
  list-style: none;
  margin-bottom: 20px;

  li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    &.selected a {
      background: $tgk-blue;
      color: #fff;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  a {
    display: block;
    line-height: 22px;
    padding: 7px 10px 7px 20px;
    text-decoration: none;
    color: $text-color;
    font-size: 15px;

    &.sub {
      padding-left: 30px;
    }
  }
}
