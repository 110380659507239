@import './vars';

// button,
// .btn {
//   padding: 8px 20px;
//   font-size: $font-size-normal;
//   cursor: pointer;
//   background: $button-default-bg-color;
//   color: $button-default-color;

//   &.primary {
//     background: $button-primary-bg-color;
//     color: $button-primary-color;
//   }
// }

.btn-sm {
  padding: 2px 10px;
  font-size: $font-size-small;
  cursor: pointer;
  background: $button-default-bg-color;
  color: $button-default-color;

  &.primary {
    background: $button-primary-bg-color;
    color: $button-primary-color;
  }
}

.btn-group {
  @extend %btn-group;
}

button,
.btn {
  @extend %btn;

  > a {
    color: $button-primary-color;
    text-decoration: none;
  }
}

.corner-btn-group {
  font-size: 0;
  position: absolute;
  right: 16px;
  top: 16px;

  > * {
    cursor: pointer;
    vertical-align: top;
    display: inline-block;
    text-align: right;
    position: relative;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  .btn-manage {
    width: 40px;
    height: 40px;
    background: $tgk-blue;
    color: #fff;
    border-radius: 50%;
    text-decoration: none;
    box-shadow: 0 3px 8px rgba(#ccc, 0.5);

    > i {
      font-size: 13px;
      @extend %center;
    }
  }
}
