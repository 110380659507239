@import '../../styles/vars';

.modal-bg {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.modal {
  max-height: 90vh;
  position: absolute;
  width: 600px;
  padding: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 4px;
  overflow: auto;
}

.item {
  margin-bottom: 10px;

  label {
    font-size: 15px;
  }

  .required {
    color: $error-color;
    display: inline-block;
    margin-left: 5px;
  }
}
