@import '../../styles/base';

.error {
  color: $error-color;
  display: inline-block;
  margin-top: 5px;
}

.combined {
  display: flex;

  .select {
    width: 175px;
    margin-right: 5px;
  }
}
