@import '../../../styles/base';

.root {
  padding: 20px;
  flex-basis: 100%;
  position: relative;
}

.linkWrapper {
  display: flex;
  justify-content: space-between;
}

.link {
  color: $theme-color;
  text-decoration: none;
  display: block;
  padding: 10px 0;
  cursor: pointer;

  i {
    margin-right: 5px;
  }
}

.empty {
  background: $card-bg-color;
  border: 1px solid $card-border-color;
  padding: 50px;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
}
