$tgk-blue: #0d47a1;

$bg-color: #f7f7fa;
$header-color: #fff;
$menu-bg-color: #f7f7fa;
$main-color: #fff;
// $theme-color: #4b74ff;
$theme-color: $tgk-blue;
$text-color: #444;

$button-primary-bg-color: #4b74ff;
$button-primary-color: #fff;
$button-default-bg-color: #eee;
$button-default-color: #4a4a4a;

$border-color: #ccc;
$error-color: #df5345;
$warning-color: #ff6d00;
$successful-color: #43a047;
$table-header-bg-color: #f2f5f7;
$table-border-color: #d7dde2;
$card-bg-color: #f2f5f7;
$card-border-color: #d7dde2;

$placeholder-color: #999;
