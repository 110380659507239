@import '../../styles/base';

.root {
  box-sizing: border-box;
  height: $header-height;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: $header-color;
  box-shadow: 0 2px 3px 0 rgba(216, 216, 216, 0.5);
  z-index: 1;

  .logo {
    width: 810.59px * 0.115;
    height: 289.58px * 0.115;
    background: url('../../assets/logo-tgk.svg') left center no-repeat;
    height: 40px;
    left: 20px;
    @extend %vertical-center;
  }
}

.info {
  float: right;
  line-height: $header-height;
  margin-right: 20px;

  p,
  a {
    display: inline-block;
    margin: 0 10px 0 0;
  }

  a {
    color: $theme-color;
    cursor: pointer;
  }
}
