@import './colors';

$font-family: 'Helvetica', 'Lato', 'PingFangTC', sans-serif;
$font-family-mono: 'SF Mono', monospace;
$font-family-condensed: 'Roboto Condensed', sans-serif;
$font-size-large: 20px;
$font-size-normal: 16px;
$font-size-small: 13px;
$font-weight-bold: 600;
$font-weight-normal: 400;
$font-weight-light: 300;

$header-height: 60px;
$menu-width: 180px;
