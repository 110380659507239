@import '../../styles/base';

.root {
  background: $bg-color;
  position: absolute;
  @extend %fullscreen;
}

.form {
  @extend %center;
  @extend %card;
  margin: 0;
  width: 420px;
}

.item {
  margin-bottom: 20px;

  label {
    font-size: 15px;
    color: $theme-color;
    margin-bottom: 5px;
    display: block;
  }
}

.error {
  color: $error-color;
  display: inline-block;
  margin-top: 5px;
}
