.breadcrumbs {
  margin: 10px 0;
}

.breadcrumbItem {
  display: inline-block;
  span {
    display: inline-block;
    margin: 0 10px;
  }
}
