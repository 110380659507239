@import '../../../styles/base';

.locations {
  .panel {
    display: flex;
  }

  .rendered {
    width: 40%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid $border-color;
    font-size: 15px;
    font-weight: 300;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    overflow: hidden;
    min-height: 40px;
    margin-right: 10px;

    &.isDraggingOver {
      background: #eee;
    }

    &.full-width {
      width: 100%;
    }
  }

  .labeling {
    padding: $font-size-normal;
    border-radius: 5px;
    background: $card-bg-color;
    flex: 1;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 8px 0;
    max-height: 250px;
    overflow-x: hidden;
    overflow-y: auto;

    + div {
      text-align: right;
    }

    label {
      font-size: 15px;
    }
  }

  button {
    font-size: 15px;
    margin: 8px 0 0 0;
  }
}

.tag {
  padding: 4px 25px 4px 10px;
  background: $main-color;
  color: $theme-color;
  border: 1px solid $theme-color;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  border-radius: 4px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &.block {
    display: block;
  }

  &.isDragging {
    background: $theme-color;
    color: #fff;
  }

  :global(.remove) {
    right: 7px;
    color: $theme-color;
    cursor: pointer;
    font-size: 13px;
    position: absolute;
    top: 7px;
  }
}

.locationsDisabled {
  composes: locations;

  .labeling {
    display: none;
  }

  button {
    display: none;
  }

  ul {
    display: none;
  }

  .tag {
    padding: 3px 8px;
  }

  input,
  .rendered {
    background: #eee;
  }
}

.error {
  color: $error-color;
  display: inline-block;
  margin-top: 5px;
}
