@import '../styles/base';

.app {
  color: $text-color;
}

.content {
  margin-left: $menu-width;
  margin-top: $header-height;
  background: $bg-color;
  height: 100%;
  flex: 1 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-width: 980px;
}
